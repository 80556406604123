export default {
  user: null,
  userDetails: null,
  jobseeker: {
    experience: [],
    education: [],
    softSkills: [],
  },
  plans: null,
};
