export default {
  plus: [
    {
      text: "questionChoice1",
      score: 1,
      color: 1
    },
    {
      text: "questionChoice2",
      score: 2,
      color: 2
    },
    {
      text: "questionChoice3",
      score: 3,
      color: 3
    },
    {
      text: "questionChoice4",
      score: 4,
      color: 4
    },
    {
      text: "questionChoice5",
      score: 5,
      color: 5
    }
  ],
  minus: [
    {
      text: "questionChoice1",
      score: 5,
      color: 1
    },
    {
      text: "questionChoice2",
      score: 4,
      color: 2
    },
    {
      text: "questionChoice3",
      score: 3,
      color: 3
    },
    {
      text: "questionChoice4",
      score: 2,
      color: 4
    },
    {
      text: "questionChoice5",
      score: 1,
      color: 5
    }
  ]
};