<template>
  <div>
    <v-row class="auth-wrap" no-gutters style="height: 100vh">
      <!-- Left half -->
      <v-col
        class="full-h"
        cols="12"
        md="6"
        order="2"
        order-md="1"
        style="position: relative;"
      >
        <div class="hero-image d-flex justify-content-center align-center">
          <!-- <v-skeleton-loader type="list-item"></v-skeleton-loader> -->
        </div>
        <v-overlay :absolute="true" :value="true">
          <v-img
            :src="require('@/assets/jonder_white.png')"
            max-width="212"
          ></v-img>
        </v-overlay>
      </v-col>

      <!-- Right half -->
      <v-col
        class="right-half d-flex full-h"
        style="overflow: auto"
        cols="12"
        md="6"
        order="1"
        order-md="2"
      >
        <v-container class="mx-auto my-auto py-5">
          <!-- AuthLogin skeleton -->
          <v-col
          class="full-h"
          cols="12"
          order="2"
          order-md="1"
          style="position: relative;"
        >

          <v-row>
            <v-col cols="12" md="12" class="mb-4">
              <v-skeleton-loader
                class="mx-auto border"
                max-width="200"
                max-height="100"
                type="image, title"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-container>
              <v-col cols="12" md="12" class="mb-4">
                <v-skeleton-loader
                  max-width="400"
                  type="list-item-two-line"
                ></v-skeleton-loader>
              </v-col>
            </v-container>
          </v-row>
        </v-col>
         
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LoginSkeleton",
};
</script>

<style lang="scss" scoped>
.auth-login-wrap {
  max-width: 450px;
  .profile-label,
  .login-by-email {
    color: $medium-grey;
    font-weight: normal;
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 55px !important;
}

@media (max-width: 600px) {
  .auth-login-wrap {
    width: 90%;
  }
}
</style>
